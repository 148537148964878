import { createTheme } from "@mui/material";

let mingleSpacing = [0, 4, 8, 12, 16, 20, 24, 28, 32, 72, 168];
// declare module "@mui/material/styles" {
//   interface BreakpointOverrides {
//     xs: true; // removes the `xs` breakpoint
//     sm: true;
//     md: true;
//     lg: true;
//     xl: true;
//     // mobile: true; // adds the `mobile` breakpoint
//     // tablet: true;
//     // laptop: true;
//     // desktop: true;
//   }
// }
// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      main: "#034191",
      light: "#2A356B",
      dark: "#0E0E10",
    },
    secondary: {
      main: "#0B1ECF",
      light: "#EAF3FF",
      // dark: "",
    },
    error: {
      main: "#E70000",
      light: "#FDE6E6",
      // dark: "",
    },
    warning: {
      main: "#FFDB1D",
      light: "#FFFBE8",
      // dark: "",
    },
    info: {
      main: "#3183FF",
      light: "#EAF3FF",
    },
    success: {
      main: "#68F3CB",
      light: "#E0FCF5",
      dark: "#00B670",
    },
    text: {
      primary: "#0E0E10",
      secondary: "",
      disabled: "",
    },
    background: {
      paper: "#E9EFFD",
      default: "#F4F6FB",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    h2: { fontSize: "1.75rem", fontWeight: 700 },
    h3: { fontSize: "1.5rem", fontWeight: 700 },
    h4: { fontSize: "1rem", fontWeight: 700 },
    h5: { fontSize: "0.875rem", fontWeight: 700 },
    h6: { fontSize: "0.75rem", fontWeight: 700 },
    subtitle1: { fontSize: "1.125rem" },
    subtitle2: { fontSize: "1rem" },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    caption: { fontSize: "0.75rem" },
    overline: { fontSize: "0.65rem", textTransform: "uppercase" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 656,
      md: 900,
      lg: 1200,
      xl: 1440,
      // mobile: 375,
      // tablet: 900,
      // laptop: 1200,
      // desktop: 1980,
    },
  },
  spacing: mingleSpacing,
  components: {
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple!
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.4,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& input": {
            backgroundColor: "#F7F7F8",
            color: "#53585F",
            border: 0,
            paddingLeft: `${mingleSpacing[6]}px `,
            paddingRight: `${mingleSpacing[6]}px `,
            // fontSize: "1.125rem",
            fontSize: "1rem",
            borderRadius: 40,
            "&:-webkit-autofill": {
              borderRadius: 40,
            },
          },
          "& fieldset": {
            // backgroundColor: "#F7F7F8",
            // color: "#53585F",
            border: 0,
            fontSize: "1.125rem",
            borderRadius: 40,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot

        root: {
          // Some CSS
          fontSize: "1rem",
          borderRadius: "30px",
          boxShadow: "none",

          fontWeight: 700,
          textTransform: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "#68F3CB",
        },
        sizeSmall: {
          fontSize: ".875rem",
          padding: `${mingleSpacing[2]}px ${mingleSpacing[6]}px `,
        },
        sizeMedium: {
          padding: `${mingleSpacing[3]}px ${mingleSpacing[8]}px `,
        },
        sizeLarge: {
          borderRadius: "40px",
          fontSize: "1.125rem",
          padding: `${mingleSpacing[6]}px ${mingleSpacing[8]}px `,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "1px solid #F3F4F6",
          fontSize: "1rem",
          boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
          background: "#fff",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontWeight: 700,
          textTransform: "uppercase",
          margin: `0 auto ${mingleSpacing[4]}px auto`,
        },
        sizeSmall: {
          fontSize: "0.75rem",
          padding: `14px ${mingleSpacing[3]}px `,
        },
        sizeMedium: {
          borderRadius: "20px",
          fontSize: "1.125rem",
          padding: `${mingleSpacing[5]}px ${mingleSpacing[4]}px `,
        },
        colorPrimary: {
          background: "rgba(11, 30, 207, 0.12)",
          color: "#0B1ECF",
        },
        colorSecondary: {
          background: "rgba(104, 243, 203, 0.12)",
          color: "#68F3CB",
        },
      },
    },
  },
});
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.up("sm")]: {
    fontSize: "3rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "4.5rem",
  },
};
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.up("sm")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.7rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "4rem",
  },
};
theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.up("lg")]: {
    fontSize: "3.25rem",
  },
};
theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
};
theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.125rem",
  },
};
theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
};
theme.typography.body1 = {
  ...theme.typography.body1,
  // lineHeight: "1.6rem",
  lineHeight: "1.4rem",
  [theme.breakpoints.up("lg")]: {
    // fontSize: "1.2rem",
    fontSize: "1rem",
  },
  [theme.breakpoints.up("xl")]: {
    // fontSize: "1.3rem",
  },
};
theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.up("lg")]: {
    // fontSize: "1.125rem",
    fontSize: ".875rem",
  },
};
// theme = responsiveFontSizes(theme);
export default theme;
